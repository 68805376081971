import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization } from "selectors/organizations";
import { getPricesData } from "selectors/metaData";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";
import iconAiRobot from "./icons/aiRobot.svg";
import iconCalculator from "./icons/calculator.svg";
import iconChartLineUp from "./icons/chartLineUp.svg";
import iconChats from "./icons/chats.svg";
import iconChatsCircle from "./icons/chatsCircle.svg";
import iconCirclesFour from "./icons/circlesFour.svg";
import iconClockCounterClockwise from "./icons/clockCounterClockwise.svg";
import iconCpu from "./icons/cpu.svg";
import iconDatabase from "./icons/database.svg";
import iconFileSearch from "./icons/fileSearch.svg";
import iconRepeat from "./icons/repeat.svg";

const SelectRpaBlock = ({ rpaMode, setRpaMode }) => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const { currency, billingId, annualBilling, appsumoValue } = useSelector(getActiveOrganization);

  const { monthlyPrices, annualPrices } = useSelector(getPricesData);

  const prices = annualBilling ? annualPrices : monthlyPrices;

  const [
    ,
    regularWorkflowFeatureA,
    regularWorkflowFeatureB,
    regularWorkflowFeatureC,
    regularWorkflowFeatureD,
    regularWorkflowFeatureE
  ] = messages.onboardingRegularWorkflow;

  const [
    ,
    rpaWorkflowFeatureA,
    rpaWorkflowFeatureB,
    rpaWorkflowFeatureC,
    rpaWorkflowFeatureD,
    rpaWorkflowFeatureE,
    rpaWorkflowFeatureF,
    rpaWorkflowFeatureG,
    rpaWorkflowFeatureH
  ] = messages.onboardingRpaWorkflow;

  const regularPrice = useMemo(() => {
    if (!billingId) return null;
    try {
      const price = prices.regular[currency] || prices.regular[Constants.DEFAULT_CURRENCY];

      return Utils.toMoneyString(
        price,
        prices.regular[currency] ? currency : Constants.DEFAULT_CURRENCY,
        price % 1 ? 1 : 0
      );
    } catch (error) {
      return null;
    }
  }, [currency, billingId, prices]);

  const roboticPrice = useMemo(() => {
    if (!billingId) return null;
    try {
      const price = prices.robotic[currency] || prices.robotic[Constants.DEFAULT_CURRENCY];

      return Utils.toMoneyString(
        price,
        prices.robotic[currency] ? currency : Constants.DEFAULT_CURRENCY,
        price % 1 ? 1 : 0
      );
    } catch (error) {
      return null;
    }
  }, [currency, billingId, prices]);

  const pricePeriodLabel = useMemo(() => {
    return (annualBilling ? uiTexts.year : uiTexts.month).toLowerCase();
  }, [uiTexts, annualBilling]);

  const handleBlockClick = useCallback(({ currentTarget: { dataset: { rpa } } }) => {
    setRpaMode(!!rpa);
  }, [setRpaMode]);

  return (
    <div className={Css.selectRpaBlock}>
      <div className={Css.header}>{messages.addBusinessFirstStep}</div>
      <div className={Css.blocks}>
        {!appsumoValue && (
          <div
            className={classNames(Css.block, !rpaMode && Css.selected)}
            onClick={handleBlockClick}>
            <div className={Css.title}>
              <div>{uiTexts.dataEntryAutomationHub}</div>
            </div>
            <div className={Css.price}>
              {regularPrice ? <><b>{regularPrice}</b><span>{` / ${pricePeriodLabel}`}</span></> : null}
            </div>
            <div>{uiTexts.keyFeatures}</div>
            <div className={Css.items}>
              <div className={Css.item}>
                <img src={iconClockCounterClockwise} />
                <div>{regularWorkflowFeatureA}</div>
              </div>
              <div className={Css.item}>
                <img src={iconChatsCircle} />
                <div>{regularWorkflowFeatureB}</div>
              </div>
              <div className={Css.item}>
                <img src={iconCpu} />
                <div>{regularWorkflowFeatureC}</div>
              </div>
              <div className={Css.item}>
                <img src={iconDatabase} />
                <div>{regularWorkflowFeatureD}</div>
              </div>
              <div className={Css.item}>
                <img src={iconFileSearch} />
                <div>{regularWorkflowFeatureE}</div>
              </div>
            </div>
            <div className={Css.integration}>
              <div className={Css.text}>{uiTexts.integrationWith}</div>
              {IntegrationServices.getFullIntegratedList().map(({ value, label, logo }) => {
                return (
                  <img key={value} src={logo} title={label} alt={label} />
                );
              })}
            </div>
          </div>
        )}
        <div
          data-rpa="true"
          className={classNames(Css.block, rpaMode && Css.selected)}
          onClick={handleBlockClick}>
          <div className={Css.title}>
            <div>{uiTexts.roboticAiBookkeeper}</div>
          </div>
          <div className={Css.price}>
            {roboticPrice ? <><b>{roboticPrice}</b><span>{` / ${pricePeriodLabel}`}</span></> : null}
          </div>
          <div>{uiTexts.keyFeatures}</div>
          <div className={Css.items}>
            <div className={Css.item}>
              <Icons.CheckCircle weight="bold" className={CommonCss.positiveText} />
              <div><b>{rpaWorkflowFeatureH}</b></div>
            </div>
            <div className={Css.item}>
              <img src={iconCalculator} />
              <div>{rpaWorkflowFeatureA}</div>
            </div>
            <div className={Css.item}>
              <img src={iconRepeat} />
              <div>{rpaWorkflowFeatureB}</div>
            </div>
            <div className={Css.item}>
              <img src={iconCirclesFour} />
              <div>{rpaWorkflowFeatureC}</div>
            </div>
            <div className={Css.item}>
              <img src={iconChats} />
              <div>{rpaWorkflowFeatureD}</div>
            </div>
            <div className={Css.item}>
              <img src={iconChartLineUp} />
              <div>{rpaWorkflowFeatureE}</div>
            </div>
            <div className={Css.item}>
              <img src={iconAiRobot} />
              <div>{rpaWorkflowFeatureF}</div>
            </div>
            <div className={Css.item}>
              <img src={iconCpu} />
              <div>{rpaWorkflowFeatureG}</div>
            </div>
          </div>
          <div className={Css.warning}>
            <Icons.WarningCircle />
            <span>{messages.postSignUpModalWarning}</span>
          </div>
          <div className={Css.integration}>
            <div className={Css.text}>{uiTexts.integrationWith}</div>
            {IntegrationServices
              .getFullIntegratedList()
              .filter(({ availableForRpa }) => availableForRpa)
              .map(({ value, label, logo }) => {
                return (
                  <img key={value} src={logo} title={label} alt={label} />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SelectRpaBlock);
